<template>
    <div class="LinkComponent">
        <iframe :src="linkUrl"  width="100%" height="100%" frameborder="0" scrolling="auto" id="outerLink"/>
    </div>
</template>

<script>
    import { ref,reactive,toRefs,onMounted,computed,watch,getCurrentInstance,nextTick,defineComponent} from 'vue';
    export default defineComponent({
        name: "LinkComponent",
        props: {
            engineParams: {//dialog传给引擎的参数，（这些参数是外界调用dialog的时候传给dialog的，然后dialog再传给引擎）
                type: Object
            }
        },
        setup(props,context){
            const appInstant = getCurrentInstance();
            let {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            const engineParams=props.engineParams;//外界传到引擎的参数（调用dialog组件传入的参数）
            let dataObj=reactive({
                linkUrl:engineParams.linkUrl
            })
            onMounted(async ()=>{
                // console.log(engineParams);
            })
            return {
                ...toRefs(dataObj)
            }
        }
    })
</script>

<style scoped>
    .LinkComponent{
        width: 100%;
        height: 100%;
    }
</style>